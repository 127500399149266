import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of, switchMap } from 'rxjs';

import { EndPointApi } from '../../_helpers/endpointapi';
import { AlarmQuery, IAlarmHistory } from '../../_models/alarm';
import { Tag } from '../../_models/device';
import { DaqQuery } from '../../_models/hmi';
import { ProjectData, ProjectDataCmdType, UploadFile } from '../../_models/project';
import { CommanType } from '../command.service';
import { ResourceStorageService } from './resource-storage.service';

@Injectable()
export class ResWebApiService implements ResourceStorageService {
  private endPointConfig: string = EndPointApi.getURL();
  private javEndPoint = EndPointApi.getJavaEndpoint();
  public onRefreshProject: () => boolean;

  constructor(private http: HttpClient) {}

  init(): boolean {
    return true;
  }

  getDemoProject(): Observable<any> {
    return this.http.get<any>('./assets/project.demo.fuxap', {});
  }

  getStorageProject(): Observable<any> {
    return this.http.get<any>(this.endPointConfig + '/api/project', {}).pipe(
      map((res: any) => {
        if (!res.success) {
          throw new Error(res.message);
        }
        const raw = res.data;
        const devices = {};
        if (raw.devices) {
          for (let item of raw.devices) {
            devices[item.id] = item;
          }
        }
        return {
          ...raw,
          devices,
        };
      }),
    );
  }

  javGetStorageProject(): Observable<any> {
    return this.http.get<any>(this.javEndPoint + 'project', {}).pipe(
      map((res: any) => {
        if (!res.success) {
          throw new Error(res.message);
        }
        const raw = res.data;
        const devices = {};
        if (raw.devices) {
          for (let device of raw.devices) {
            const tags = {};
            if (device.tags) {
              for (let tag of device.tags) {
                tags[tag.id] = tag;
              }
            }
            devices[device.id] = { ...device, tags };
          }
        }
        if (raw.graphs) {
          raw.graphs.forEach((graph: any) => {
            graph.property.function = JSON.parse(graph.property.function);
          });
        }
        (raw?.hmi?.views || []).forEach((view: any) => {
          if (!view?.items) {
            view.items = {};
          } else {
            const items = {};
            view.items.forEach((item: any) => {
              if (item.property) {
                if (item.property.actions) {
                  item.property.actions = JSON.parse(item.property.actions);
                }
                if (item.property.events) {
                  item.property.events = JSON.parse(item.property.events);
                }
                if (item.property.options) {
                  item.property = {
                    ...item.property,
                    options: JSON.parse(item.property.options),
                  };
                }
                if (item.property.ranges) {
                  const ranges = item.property.ranges;
                  ranges.forEach((range: any) => {
                    if (range.style) {
                      range.style = JSON.parse(range.style);
                    }
                  });
                }
              }
              items[item.id] = item;
            });
            view.items = items;
          }
        });
        console.log('get project', {
          ...raw,
          devices,
        });
        return {
          ...raw,
          devices,
        };
      }),
    );
  }

  setServerProject(prj: ProjectData) {
    console.log('set project', prj);
    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<ProjectData>(this.endPointConfig + '/api/project', prj, { headers: header });
  }

  javSetServerProject(prj: ProjectData) {
    console.log('init project', prj);
    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<ProjectData>(this.javEndPoint + 'project/init', { headers: header });
  }

  javSetProjectName(name: string) {
    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    console.log('set project name', name);
    return this.http.post<ProjectData>(this.javEndPoint + 'project/set-name', { name }, { headers: header });
  }

  javSetChart(data: any) {
    const body = {
      charts: data,
    };
    return this.http.post<any>(this.javEndPoint + 'graph/set-chart', body);
  }

  javSetGraph(data: any) {
    const listGraph = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      listGraph.push({
        ...item,
        property: {
          ...item.property,
          function: JSON.stringify(item.property.function),
        },
      });
    }
    const body = {
      graphs: listGraph,
    };
    return this.http.post<any>(this.javEndPoint + 'graph/set-graph', body);
  }

  setServerProjectData(cmd: ProjectDataCmdType, data: any) {
    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    let params = { cmd: cmd, data: data };
    return this.http.post<any>(this.javEndPoint + '/api/projectData', params, { headers: header });
  }

  javSetView(data: any) {
    const newItems = [];
    if (Object.keys(data?.items || {}).length !== 0) {
      Object.keys(data.items).forEach((key) => {
        const item = { ...data.items[key] };
        if (item.property) {
          if (!item.property.actions) {
            item.property.actions = [];
          }
          if (!item.property.events) {
            item.property.events = [];
          }
          if (!item.property.ranges) {
            item.property.ranges = [];
          } else {
            item.property.ranges.forEach((range: any) => {
              if (!range.style) {
                range.style = [];
              }
            });
          }
        }
        newItems.push({ ...data.items[key] });
      });
    }
    const body = {
      ...data,
      items: newItems,
    };
    console.log('set view', body);
    return this.http.post(this.javEndPoint + 'view/set-view', body);
  }

  javRemoveView(data: any) {
    return this.http.delete(`${this.javEndPoint}view/${data.id}`);
  }

  javSetDevice(data: any) {
    const tags: any[] = [];
    Object.keys(data?.tags || {}).forEach((key) => {
      const tag = data.tags[key];
      const javaTag = {
        ...tag,
        options: this.getTagOptions(tag),
      };
      tags.push(javaTag);
    });
    const body = {
      ...data,
      tags,
    };
    console.log('set device', body);
    return this.http.post(`${this.javEndPoint}device/set-device`, body);
  }

  javSetScript(data: any) {
    return this.http.post(`${this.javEndPoint}script`, data);
  }

  javRemoveScript(data: any) {
    return this.http.delete(`${this.javEndPoint}script/${data}`);
  }

  public getTagOptions(tag: Tag) {
    if (!tag.options) {
      return null;
    }
    if (!tag.options.subs) {
      return tag.options;
    }
    if (typeof tag.options.subs === 'string') {
      return tag.options.subs;
    }
    return JSON.stringify(tag.options.subs);
  }

  javRemoveDevice(deviceId: any) {
    return this.http.delete(`${this.javEndPoint}device/${deviceId}`);
  }

  javSetTag(data: any) {
    return this.http.delete(`${this.javEndPoint}device/${data.id}`);
  }

  javRemoveTag(data: any) {
    return this.http.delete(`${this.javEndPoint}device/${data.id}`);
  }

  javSetDeviceSecurity(id: string, value: string) {
    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    let params = { query: 'security', name: id, value: value ? value : null };
    return this.http.post(`${this.javEndPoint}device/set-device-security`, { headers: header, params: params });
  }

  javRemoveDeviceSecurity(data: any) {
    return of({});
  }

  javSetLayout(data: any) {
    return this.http.post(`${this.javEndPoint}layout`, data);
  }

  uploadFile(resource: any, destination?: string): Observable<UploadFile> {
    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    let params = { resource, destination };
    return this.http.post<any>(this.javEndPoint + 'app/upload-cert', params, { headers: header }).pipe(
      map((res: any) => {
        return res.data;
      }),
    );
  }

  getDeviceSecurity(id: string): Observable<any> {
    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    let params = { query: 'security', name: id };
    return this.http.get<any>(this.javEndPoint + 'device/security', { headers: header, params: params }).pipe(
      map((res: any) => {
        return res.data;
      }),
    );
  }

  setDeviceSecurity(id: string, value: string): Observable<any> {
    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    let params = { query: 'security', name: id, value: value ? value : null };
    const body = {
      header,
      params,
    };
    return this.http.post(`${this.javEndPoint}device/security`, body);
  }

  getAlarmsValues(): Observable<any> {
    return this.http.get<any>(this.endPointConfig + '/api/alarms', {});
  }

  getAlarmsHistory(query: AlarmQuery): Observable<IAlarmHistory[]> {
    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    const requestOptions: Object = {
      /* other options here */
      headers: header,
      params: {
        start: query.start.getTime(),
        end: query.end.getTime(),
      },
      observe: 'response',
    };
    return this.http.get<IAlarmHistory[]>(this.endPointConfig + '/api/alarmsHistory', requestOptions).pipe(
      switchMap((response: any) => {
        if (response.body === null || response.body === undefined) {
          return of([]);
        }
        return of(response.body);
      }),
      map((body: IAlarmHistory[]) => body),
    );
    // // let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    // let params = { query: JSON.stringify(query) };
    // return this.http.get<any>(this.endPointConfig + '/api/alarmsHistory', { headers: header, params: params });
  }

  setAlarmAck(name: string): Observable<any> {
    return new Observable((observer) => {
      let header = new HttpHeaders({ 'Content-Type': 'application/json' });
      this.http.post<any>(this.endPointConfig + '/api/alarmack', { headers: header, params: name }).subscribe(
        (result) => {
          observer.next();
        },
        (err) => {
          observer.error(err);
        },
      );
    });
  }

  checkServer(): Observable<any> {
    return this.http.get<any>(this.javEndPoint + 'app/settings').pipe(
      map((res: any) => {
        const data = res.data?.settings || [];
        const pricingList = res.data?.pricingList || [];
        const dic = {
          pricingList: pricingList
        };
        data.forEach((item) => {
          dic[item.key] = item.value;
        });
        return dic;
      }),
    );
  }

  getAppId() {
    return ResourceStorageService.prjresource;
  }

  getDaqValues(query: DaqQuery): Observable<any> {
    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    let params = { query: JSON.stringify(query) };
    return this.http.get<any>(this.endPointConfig + '/api/daq', { headers: header, params });
  }

  getTagsValues(tagsIds: string[]): Observable<any> {
    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    let params = { ids: JSON.stringify(tagsIds) };
    return this.http.get<any>(this.endPointConfig + '/api/getTagValue', { headers: header, params });
  }

  runSysFunction(functionName: string, parameters?: any): Observable<any> {
    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    let params = { functionName: functionName, parameters: parameters };
    return this.http.post<any>(this.endPointConfig + '/api/runSysFunction', { headers: header, params: params });
  }

  heartbeat(activity: boolean): Observable<any> {
    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(this.javEndPoint + 'app/heartbeat', { headers: header, params: activity });
  }

  downloadFile(fileName: string, type: CommanType): Observable<Blob> {
    let header = new HttpHeaders({ 'Content-Type': 'application/pdf' });
    let params = {
      cmd: type,
      name: fileName,
    };
    return this.http.get(this.endPointConfig + '/api/download', {
      headers: header,
      params: params,
      responseType: 'blob',
    });
  }
}
