import { Component, OnInit } from '@angular/core';
import { RealtimePricingModel } from '../../_models/realtime-pricing.model';
import { ResponseApiModel } from '../../_models/response-api.model';
import { RealtimePricingService } from '../../_services/add-in/realtime-pricing.service';
import { HmiService } from '../../_services/hmi.service';

@Component({
  selector: 'app-realtime-pricing',
  templateUrl: './realtime-pricing.component.html',
  styleUrls: ['./realtime-pricing.component.scss'],
})
export class RealtimePricingComponent implements OnInit {
  dataSource: RealtimePricingModel[] = [];
  displayedColumns: string[] = [
    'time',
    'intervalStart',
    'intervalEnd',
    'market',
    'location',
    'locationType',
    'lmp',
    'energy',
    'congestion',
    'loss',
  ];

  constructor(
    private realtimePricingService: RealtimePricingService,
    private hmiService: HmiService,
  ) {}

  ngOnInit(): void {
    this.realtimePricingService.getAll().subscribe((res: ResponseApiModel) => {
      if (res.success) {
        this.dataSource = res.data;
      }
    });
    this.hmiService.onRealtimePricingChanged.subscribe((data: any) => {
      this.dataSource = [...this.dataSource, ...data];
    });
  }
}
