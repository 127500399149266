import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UploadFile } from '../../_models/project';
import { AppService } from '../app.service';
import { ResourceStorageService } from './resource-storage.service';
import { ResWebApiService } from './reswebapi.service';

@Injectable({
  providedIn: 'root',
})
export class RcgiService {
  public rcgi: ResourceStorageService;

  constructor(
    private reseWebApiService: ResWebApiService,
    private appService: AppService,
  ) {
    this.rcgi = reseWebApiService;
  }

  uploadFile(file: any, destination?: string): Observable<UploadFile> {
    return this.rcgi.uploadFile(file, destination);
  }
}
