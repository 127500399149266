declare function require(moduleName: string): any;

export const environment = {
  version: require('../../package.json').version,
  production: false,
  apiEndpoint: null,
  apiPort: 1881,
  serverEnabled: true,
  javUrl: 'http://155.138.240.44:8080/api/',
  socketUrl: 'ws://155.138.240.44:8080/rsocket',
  type: null,
};
